import React from 'react'
import Menu from './components/Menu'
import Spa from './components/Spa'
import './App.css'

function App() {
  return (
    <div className="App" id="About">
      <Menu />
      <Spa />
    </div>
  )
}

export default App
