import React, { Component } from 'react'
import '../stylesheets/Footer.css'

export default class Footer extends Component {
  render() {
    return (
      <div className="Footer-container">
        <div className="Center-text-container">
          <h5>&copy; Made with React by Kyle George</h5>
        </div>
      </div>
    )
  }
}
