import React, { Component } from 'react'

export default class SkillsCard extends Component {
  render() {
    return (
      <div className="skill-card">
        <img src={require(`../images/${this.props.skillInfo.image}`)} alt="" />
        <h4>{this.props.skillInfo.title}</h4>
      </div>
    )
  }
}
