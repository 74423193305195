import React, { Component } from 'react'
import Footer from './Footer'
import '../stylesheets/Contact.css'

export default class Contact extends Component {
  state = {
    subject: '',
    body: '',
  }

  updateState = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    return (
      <div className="outer-container">
        <div className="Contact-container" id="Contact">
          <div className="Contact-section">
            <h1>Send Me An Email</h1>
            <p>
              If you have any questions or concerns please feel free to complete
              the form to the right. Be as descriptive as you can and I will get
              back to you as promtly as possible!
            </p>
          </div>
          <form>
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              onChange={this.updateState}
              placeholder="Write subject here..."
            ></input>
            <label>Body</label>
            <textarea
              name="body"
              onChange={this.updateState}
              placeholder="What may I help you with?..."
            ></textarea>

            <a
              href={`mailto:kyleagrge@gmail.com?subject=${this.state.subject}&body=${this.state.body}`}
            >
              Send Email
            </a>
          </form>
        </div>
        <Footer />
      </div>
    )
  }
}
