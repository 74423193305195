export const skills = [
  { image: 'react.png', title: 'React.js' },
  { image: 'javascript.png', title: 'Javascript' },
  { image: 'firebase.png', title: 'Firebase' },
  { image: 'html.png', title: 'HTML' },
  { image: 'css.png', title: 'CSS' },
  { image: 'ruby.png', title: 'Ruby' },
  { image: 'bootstrap.png', title: 'Bootstrap' },
  { image: 'uiux.png', title: 'UI/UX' },
  { image: 'photoshop.png', title: 'Photoshop' },
]
