import React, { Component } from 'react'
import { skills } from './skills-info'
import SkillsCard from './SkillsCard'
import '../stylesheets/Skills.css'

export default class Skills extends Component {
  loadSkillCards = () => {
    return skills.map((skill) => {
      return <SkillsCard key={skill.image} skillInfo={skill} />
    })
  }

  render() {
    return (
      <div className="Skills" id="Skills">
        <h1 className="heading-title">Skills</h1>
        <div className="skills-section">{this.loadSkillCards()}</div>
      </div>
    )
  }
}
