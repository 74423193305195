import React, { Component } from 'react'
import { projects } from './project-info.js'
import ProjectCard from './ProjectCard.js'
import '../stylesheets/Projects.css'

export default class Projects extends Component {
  state = {
    filteredState: '',
  }

  showProjects = () => {
    console.log('rerender', this.state.filteredState)

    if (this.state.filteredState) {
      let filteredProjects = projects.filter((proj) => {
        return proj.type === this.state.filteredState
      })

      return filteredProjects.map((proj) => {
        return <ProjectCard key={proj.name} project={proj} />
      })
    } else {
      return projects.map((proj) => {
        return <ProjectCard key={proj.name} project={proj} />
      })
    }
  }

  filterProjectList = (event) => {
    // event.preventDefault()
    this.setState({ filteredState: event.target.name })
  }
  render() {
    console.log(this.state.filteredState)
    return (
      <div className="Project-Container" id="Projects">
        <h1 className="heading-title">Portfolio</h1>
        <div className="filter-tab">
          <button name="psd" onClick={(e) => this.filterProjectList(e)}>
            PSD Mockups
          </button>
          <button name="" onClick={(e) => this.filterProjectList(e)}>
            All
          </button>
          <button name="npm" onClick={(e) => this.filterProjectList(e)}>
            NPM Packages
          </button>
        </div>
        <div className="Project-Grid">{this.showProjects()}</div>
      </div>
    )
  }
}
