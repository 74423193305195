import React, { Component } from 'react'
import resume from '../documents/resume.pdf'
import '../stylesheets/Menu.css'

export default class Menu extends Component {
  state = {
    class: 'hide',
  }

  clickedMenu = () => {
    this.state.class === 'hide'
      ? this.setState({ class: 'show' })
      : this.setState({ class: 'hide' })
  }

  render() {
    return (
      <div>
        <div className="Menu-Container main-color">
          <ul>
            <li>
              <a href="#About">About</a>
            </li>
            <li>
              <a href="#Skills">Skills</a>
            </li>
            <li>
              <a href="#Projects">Portfolio</a>
            </li>
            <li>
              <a href="#Blogs">Blogs</a>
            </li>
            <li>
              <a href="#Contact">Contact</a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/kyle-george-7aa2a387/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://github.com/kgeorge24"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </li>
            <li>
              <a href={resume} target="_blank" rel="noopener noreferrer">
                Resume
              </a>
            </li>
          </ul>
          <ul>
            <li onClick={this.clickedMenu}>
              <img src={require('../images/hamburger-menu-white.png')} alt="" />
            </li>
          </ul>
        </div>
        {/* Mobile Menu */}
        <div className={this.state.class}>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/kyle-george-7aa2a387/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://github.com/kgeorge24"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </li>
            <li>
              <a href={resume} target="_blank" rel="noopener noreferrer">
                Resume
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
