import React, { Component } from 'react';
import '../stylesheets/ProjectCard.css';

export default class ProjectCard extends Component {
  render() {
    return (
      <div className="project-card">
        <a
          href={this.props.project.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img
              src={require(`../images/${this.props.project.image}`)}
              alt=""
            />
          </div>
          <div>
            <h1>{this.props.project.name}</h1>
          </div>
        </a>
      </div>
    );
  }
}
