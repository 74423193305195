import React, { Component } from 'react'
import '../stylesheets/Heading.css'

export default class Heading extends Component {
  render() {
    return (
      <div className="Heading flex-container grey-color">
        <div className="heading-words flex-item">
          <h1>
            <span className="name">Kyle George </span>
            <br></br>
            <div>Full Stack Web Developer</div>
          </h1>
          <p>
            Full Stack Web Developer with a passion for front end designing and
            creating cutting edge features. With experience in Ruby on Rails,
            JavaScript, React/Redux and a background in HTML and CSS. I bring
            strong skills in problem solving and building responsive designs
            that help enterprises, agencies, and startups gain visual presence
            as a brand.
          </p>
          {/* <button>
            <a href="#Contact">Hire Me</a>
          </button> */}
        </div>
      </div>
    )
  }
}
