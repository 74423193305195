export const projects = [
  {
    name: 'Simple Login Package',
    description: '',
    link: 'https://www.npmjs.com/package/simple-login-form',
    image: 'simplelogin.png',
    type: 'npm',
  },
  {
    name: 'Landing Page Mock',
    description: '',
    link: 'https://landingpagemock.netlify.app/',
    image: 'landingpage.jpeg',
    type: 'psd',
  },
  {
    name: 'Creature Quiz',
    description: '',
    link: 'https://creaturequiz.netlify.app/',
    image: 'creaturequiz.jpeg',
    type: '',
  },
  {
    name: 'Handsfree-Ecommerce',
    description: '',
    link: 'https://handsfree-eccomerce.netlify.app/',
    image: 'handsfree.jpeg',
    type: '',
  },
  {
    name: 'Scanape',
    description: '',
    link: 'https://www.youtube.com/watch?v=Pw5NOJWG9XI&t=5s',
    image: 'scanape.jpeg',
    type: '',
  },
  {
    name: 'Court St. Scheduler',
    description: '',
    link: 'https://www.youtube.com/watch?v=lojt2_Ub9YE',
    image: 'court.jpeg',
    type: '',
  },
];


