import React, { Component } from 'react'
import '../stylesheets/Blogs.css'

export default class Blogs extends Component {
  render() {
    return (
      <div className="Blogs" id="Blogs">
        <h1 className="heading-title">Blogs</h1>
        <div className="Blogs-container">
          <div className="Blog-post">
            <a
              href="https://medium.com/@capitalkreationz/mom-go-clean-your-code-its-a-mess-3059ecf0abbe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../images/messcode.png')} alt="" />
            </a>
          </div>
          <div className="Blog-post">
            <a
              href="https://medium.com/@capitalkreationz/client-side-routing-with-react-router-7f58f54e9754"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../images/router.png')} alt="" />
            </a>
          </div>
          <div className="Blog-post">
            <a
              href="https://medium.com/@capitalkreationz/to-plan-or-not-to-plan-that-is-the-question-1875d4ece75"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../images/plan.png')} alt="" />
            </a>
          </div>
        </div>
      </div>
    )
  }
}
