import React, { Component } from 'react';
import '../stylesheets/Spa.css';
import Heading from './Heading';
import Skills from './Skills';
import Projects from './Projects';
import Blogs from './Blogs';
import Contact from './Contact';

export default class Spa extends Component {
  render() {
    return (
      <div className="Spa-Container">
        <Heading />
        <Skills />
        <Projects />
        <Blogs />
        <Contact />
      </div>
    );
  }
}
